.conversationBox {
  // width: calc(100% - 80px - 280px);
  border-radius: 20px;
  // width: 800px;
  // kim update
  width: 100%;
  position: relative;
  background-color: #ffffff;
  .ConversationName {
    border: 1px solid #e9ebed;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .conversationBoxContent {
    position: relative;
    // position: relative;
    height: 520px;
    // overflow-y: scroll;
    overflow: hidden;
    padding: 20px 30px 40px 20px;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    .messageList {
      width: 100%;
      .moreBtn {
        font-size: 12px;
        color: #9ea7af;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 20px;
        .hisRecordBrn {
          color: #007d96;
          cursor: pointer;
        }
      }
      .messageItem {
        display: flex;
        align-items: flex-start;
        padding-bottom: 20px;
        .avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 20px;
        }
        .messageItem_right {
          .nameBox {
            padding-bottom: 4px;
            display: flex;
            align-items: center;
            font-size: 12px;
            height: 20px;
            .memberName {
              color: #9ea7af;
              padding-right: 8px;
              max-width: 260px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
          }
          .record {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .recordBox {
              background-color: #f1f5f7;
              border-radius: 6px;
              max-width: 422px;
              padding: 11px;
              word-break: break-word;
            }
            .recordImageBox {
              cursor: pointer;
              .recordImage {
                border: 1px solid #f7f7f7;
                border-radius: 8px;
              }
              .videoImage {
                position: relative;
                // width: 150px;
                // height: 150px;
                border-radius: 8px;
                .videoTime {
                  color: #2e2e2e;
                  position: absolute;
                  right: 8px;
                  bottom: 8px;
                  font-size: 12px;
                }
              }
            }
            .voice_box {
              font-size: 16;
              border-radius: 8px;
              color: var(--color);
              padding: 10px;
              display: flex;
              align-items: center;
              background-color: #f7f7f7;
            }

            .voice_right {
              flex-direction: row-reverse;
            }
            .voice_right_img {
              transform: rotateY(180deg);
            }
            .recordTime {
              padding-top: 10px;
              font-size: 12px;
              color: #9ea7af;
            }
          }
        }
      }
      .messageOut {
        flex-direction: row-reverse;
        .avatar {
          margin-left: 20px;
          padding-right: 0;
        }
        .messageItem_right {
          .record {
            align-items: flex-end;
            .recordTime {
              text-align: right;
            }
          }
        }
      }
    }
  }
  .preVideoModel {
    position: absolute;
    width: 740px;
    height: 740px;
    top: 44%;
    left: 51.5%;
    transform: translate(-50%, -50%);
    background-color: #f1f5f7;
    z-index: 1001;
    .closeModel {
      cursor: pointer;
      padding: 10px;
      text-align: right;
    }
  }
}
.inputBox {
  border-right: 1px solid #e9e9e9;
  .conversationAPP {
    padding: 20px 20px 10px;
    display: flex;
    align-items: center;
    .APPItem {
      position: relative;
      margin-right: 14px;
      cursor: pointer;
      .APPItemInput {
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
      }
    }
  }
  // kim update
  .conversationInput {
    padding: 0 20px 10px;
    min-height: 106px;
    position: relative;
  }
  .sendBtn {
    text-align: right;
    padding-right: 40px;
    padding-bottom: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
