.app-loading {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;

  // make children center
  display: flex;
  justify-content: center;
  align-items: center;

  // visible
  opacity: 1;
  visibility: visible;

  &.hide {
    transition: all 1.5s;
    opacity: 0;
    visibility: hidden;
  }
}
