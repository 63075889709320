@import './antd-vars.scss';

.ant-layout-sider {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important;
}
.ant-menu-inline {
  padding-bottom: 150px;
}
.lay_menu {
  // background: #000;
  // .ant-menu {
  //   padding-bottom: 150px;
  //   border-bottom: 0;
  // }

  .ant-menu {
    padding-right: 10px !important;
  }
  .ant-menu-item {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .ant-menu-item-selected {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-right: 3px solid transparent;
      content: '';
    }
  }
  .ant-menu-submenu-inline {
    color: #007d96;
    .ant-menu-item {
      height: 48px;
    }
  }
  .ant-menu-submenu-title > i {
    color: #007d96;
  }

  /**Sider 二级菜单下的样式 开始*/
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e5f2f4 !important;
    height: 48px;
  }
  .ant-menu-sub {
    background-color: #fff;
  }
  .ant-menu-item {
    height: 48px;
    &:active {
      color: #007d96;
      background-color: #e5f2f4;
      height: 48px;
    }
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      height: 48px;
    }
  }
  /**结束*/
  /**Sider 一级菜单下的样式 开始*/
  .ant-menu-item-selected {
    background-color: #e5f2f4 !important;
    width: 210px;
    height: 48px;
  }
  .ant-menu-submenu-title {
    &:active {
      color: #007d96;
      background-color: #e5f2f4;
      height: 48px;
    }
  }
  .ant-menu-item .ant-menu-item-icon + span {
    margin-left: 16px;
  }
  .ant-menu-submenu-title .anticon + span {
    margin-left: 16px;
  }
  .ant-menu-item .ant-menu-item-icon {
    font-size: 16px;
  }
  .ant-menu-submenu-title .anticon {
    font-size: 16px;
  }
  /**结束*/
}
aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger {
  width: 220px !important;
  max-width: 220px !important;
  flex: 0 0 220px !important;
}
.ty_txt {
  color: #9ea7af;
  cursor: pointer;
}
.table_txt {
  color: $color-txt-list !important;
}
/** 重置按钮修改的样式*/
.general {
  background: $color-selectd;
  border: 1px solid $color-selectd;
  color: $primary-color;
  &:hover {
    border: 1px solid $color-selectd;
    color: $primary-color;
    background: rgba(0, 125, 150, 0.15);
  }
}
.general_font {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: rgba(0, 125, 150, 0.15);
  color: #007d96;
  border: 0;
  &:hover {
    background: rgba(0, 125, 150, 0.15);
  }
  &:focus {
    background: rgba(0, 125, 150, 0.15);
  }
}
/** 普通按钮修改的样式 */
.img_general {
  background: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  &:hover {
    border: 1px solid $primary-color;
    background: rgba(0, 125, 150, 0.15);
    color: $primary-color;
  }
}
.img_general2 {
  background: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: 6px 10px;
  min-width: 96px !important;
  &:hover {
    border: 1px solid $primary-color;
    background: rgba(0, 125, 150, 0.15);
    color: $primary-color;
  }
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 4px;
}
/**按钮被禁用样式*/
.ant-btn[disabled] {
  color: #97cad4;
  border: 0;
  background: #e5f2f4;
  &:hover {
    color: #97cad4;
    border: 0;
    background: #e5f2f4;
  }
}
// botton
.ant-btn {
  min-width: 76px;
}

/**数字输入框显示上下选择器*/
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

// input
.ant-input[disabled],
.ant-input-number-disabled,
.ant-picker.ant-picker-disabled {
  color: $color-txt;
  background-color: #f1f5f7;
  border-color: #dbdee1;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f1f5f7;
  border-color: #dbdee1;
}
.ant-input:focus {
  box-shadow: 0 0 0 0 #fff;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 0 #fff;
}
/** 复选按钮样式 */
.ant-checkbox-inner {
  border-radius: 4px !important;
}
.ant-checkbox-disabled + span {
  color: rgba($color-txt, 0.3);
}
.ant-checkbox-disabled {
  .ant-checkbox-inner {
    background: #d9ecf0;
  }
}
/** table */
// .ant-table-tbody > tr.ant-table-row-selected > td {
//   background: transparent !important;
// }
// .ant-table-tbody > tr.ant-table-row-selected > td:nth-child(even) {
//   background: #f5fafb !important;
// }

.ant-table-thead > tr > th {
  background: #f5fafb;
}
.hide_border {
  .ant-table-tbody > tr > td {
    border-bottom: 0;
  }
  .ant-table-thead > tr > th {
    border-bottom: 0;
  }
}
.ant-table-thead > tr > th {
  &::before {
    width: 0 !important;
  }
}
.ant-table-sticky-holder {
  top: 38px !important;
}
.ant-table-column-sorters {
  justify-content: flex-start;
  .ant-table-column-title {
    width: max-content;
    flex: none;
  }
}
.table_th_right_line {
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
    border-right: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
    border-right: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:nth-last-child(1) {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:nth-last-child(1) {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-radius: 8px;
  }
  .ant-table-pagination::after {
    width: 0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr:nth-last-child(1)
    > td:nth-child(1) {
    // background: red !important;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr:nth-last-child(1)
    > td:nth-last-child(1) {
    // background: red !important;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  .ant-table-pagination.ant-pagination {
    padding: 20px 0 0;
  }
}
.ant-card-bordered {
  border: 0;
}
.hide_border_radius {
  .ant-table-container {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .ant-table table {
    border-radius: 0 !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0 !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0 !important;
  }
  &__pay-icon {
    color: #481800;
    min-width: 36px;
    margin-left: 3px;
    height: 18px;
    position: relative;
    background: linear-gradient(90deg, #ff5d54 0%, #f3d8be 0%, #e5b398 100%);
    border-radius: 4px;
    text-align: center;
    line-height: 18px;
    cursor: default;
    font-size: 12px;
  }
}
.ant-table-selection-column + .ant-table-cell {
  padding: 0px 16px 0px 0;
}
.ant-table-cell {
  padding: 6px 16px 6px 16px !important;
  height: 64px !important;
}
.ant-table-tbody > tr > td {
  height: 64px;
}

.oddRow {
  //奇数行
  background: #fff;
}

.evenRow {
  //偶数行
  background: #f5fafb !important;
  .ant-table-cell-fix-right-first {
    background: #f5fafb;
  }
}
.table_pagination_left {
  .ant-table-pagination {
    position: relative;
    .ant-pagination-total-text {
      position: absolute;
      left: 0;
      color: #9ea7af;
    }
  }
}
.ant-table-pagination {
  position: relative;
  .ant-pagination-total-text {
    position: absolute;
    left: 40px;
    color: #9ea7af;
  }
  .ant-pagination-item-active {
    border: 0 !important;
    background-color: #007d96;
  }
  .ant-pagination-item-active a {
    color: #fff !important;
  }
  &::after {
    width: 30px;
  }
}
.ant-table-pagination.ant-pagination {
  margin: 0;
  padding: 20px 0;
}

/** tag*/
.ant-tag {
  background: #fff;
  padding: 8px 10px;
  margin-bottom: 5px;
  border: 0.5px solid #dbdee1;
}

/**Paragraph more 能换行*/
.ant-typography-single-line {
  white-space: normal;
}

.ant-menu-submenu .ant-menu-item {
  padding-left: 54px !important;
  font-size: 15px;
}

.box_card {
  .ant-card-body {
    padding: 0;
  }
}

.ant-tag {
  margin-bottom: 5px !important;
  margin-top: 5px;
}
// modal
.ant-modal-title {
  font-weight: 600;
  color: $color-txt;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 600 !important;
}
.ant-modal-content {
  border-radius: 20px;
}
.ant-modal-header {
  border-radius: 20px 20px 0 0;
}
.ant-modal-confirm .ant-modal-body {
  padding: 40px 40px 20px 40px;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 10px;
}

// 最小宽度
.min_box_width {
  min-width: 1200px;
  padding-top: 0 !important;
}
.min_box_width_1400 {
  min-width: 1400px;
}
// 气泡全局样式 popover
.ant-popover-message > .anticon {
  font-size: 32px;
  margin-right: 20px;
}
.ant-popover-buttons button {
  min-width: 76px;
  height: 36px;
}

// tabs
.tabs_custom {
  .ant-tabs-tab {
    padding: 20px 0;
    font-size: 16px !important;
  }
  .ant-tabs-nav-wrap {
    padding: 0 40px;
  }
}

// form
.ant-form-item-label > label::after {
  content: ' ';
}

// radio
.ant-radio-disabled + span {
  color: rgba($color-txt, 0.3);
}

.ant-radio-wrapper-checked > .ant-radio-disabled {
  .ant-radio-inner {
    border-color: $primary-color !important;
  }

  .ant-radio-inner::after {
    // border-color: red !important;
    background-color: $primary-color !important;
  }
}
.ant-radio-checked {
  .ant-radio-inner {
    background: transparent !important;
  }
}
.ant-radio-inner {
  background: transparent !important;
}
.ant-radio-disabled {
  .ant-radio-inner {
    background: #f1f5f7 !important;
  }
}

// 遮罩
.ant-modal-mask {
  background-color: rgba(13, 34, 56, 0.2);
}

// picker
.ant-picker-focused {
  box-shadow: 0 0 0 0 #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    color: #fff !important;
    background-color: $primary-color;
    border-radius: 50%;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner::before {
  border-radius: 50%;
}

// 解决select 自定义icon 下拉 点击无法触发下拉
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

.breadcrumb_css {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 99;
  background: #f0f2f5;
  padding-bottom: 16px;
}

/** 确认框 */
.ant-popover-inner {
  border-radius: 15px;

  .ant-popover-inner-content {
    padding: 20px 40px 20px;
    box-sizing: border-box;
    .ant-popover-message {
      padding: 0px 0 33px;
    }
    .ant-popover-message > .anticon {
      font-size: 46px;
      margin-right: 30px;
      margin-top: -6px;
    }
    .ant-popover-message-title {
      padding-left: 0;
    }
    span + .ant-popover-message-title {
      padding-left: 60px;
    }
  }
}
.ant-popover-arrow-content {
  border-radius: 0 0 5px 0;
}
.ant-select-tree-checkbox-inner {
  border-radius: 2px;
}
/** 上传删除小按钮的样式 */
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  min-width: 24px !important;
  width: 24px !important;
}
.ant-tooltip-inner {
  box-shadow: 0 0px 4px 3px rgb(13 34 56 / 1%), 0 5px 6px 0 rgb(13 34 56 / 2%), 0 9px 5px 4px rgb(13 34 56 / 1%);
}
