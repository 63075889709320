.message_box {
  font-size: 16px;
  background-color: #f1f5f7;
  padding: 10px;
  border-radius: 8px;
  font-weight: 400;
  color: #0d2238;
  line-height: 22px;
  //   word-break: break-word;

  // 不管英文还是数字都换行
  word-wrap: break-word;
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .row_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .article_title {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #6e7a88;
    margin-left: 4px;
  }
  .article_content {
    // 超过两行就...
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 显示的行数
    -webkit-box-orient: vertical;
    margin-top: 6px;
  }
  .article_content_tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 显示的行数
    -webkit-box-orient: vertical;
    margin-top: 4px;
    color: #6e7a88;
  }
  /** 绑定设备 */
  .bind_device {
    background: #f3f3f3;
    border-radius: 6px;
    margin-top: 6px;
  }
  .device_list {
    display: grid;
    row-gap: 12px;
    column-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    height: 108px;

    overflow-y: auto;
    padding: 10px;
  }
  .device_item_txt {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #6e7a88;
    margin-left: 6px;
  }
  .answer_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #eff8fa;
    border-radius: 4px;
    padding: 9px 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .warp_row2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 显示的行数
    -webkit-box-orient: vertical;
  }
  .answer_item_radio {
    padding: 0 6px;
    width: 22px;
    height: 22px;

    border: 2px solid rgba(13, 34, 56, 0.15);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: #6e7a88;
    font-size: 13px;
  }
  .answer_selected {
    color: #fff;
    background: #007d96;
  }
  .answer_item_checked {
    border-radius: 4px;
  }
  .answer_item_analysis {
    padding-top: 19px;
    border-top: 1px solid rgba(13, 34, 56, 0.08);
    margin-top: 20px;
  }
  .answer_btn {
    padding: 7px 0;
    text-align: center;
    border-radius: 18px;
    border: 2px solid #007d96;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #007d96;
    margin-top: 32px;
  }
  .answer_tag {
    background: #007d96;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    padding: 2px 6px;
    margin-right: 10px;
  }
  .analysis_before {
    position: relative;
    padding-left: 11px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 2px;
      height: 14px;
      border-radius: 1.5px;
      background: #007d96;
    }
  }
  .analysis_before_content {
    color: #6e7a88;
  }
  /** 异常消息 */
  .error_title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #ef7a64;
    margin-left: 4px;
    font-size: 8px;
  }
  .error_txt {
    margin-top: 5px;
    font-size: 8px;
  }
  .bottom_txt {
    padding: 7px 0 0;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #007d96;
    border-top: 1px solid rgba(13, 34, 56, 0.08);
    margin-top: 10px;
  }
}

.message_box_dark {
  background-color: #f3f3f3;
}

.attention_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #0d2238;
  margin-bottom: 16px;
}
.attention_qrBox {
  position: relative;
  width: 100%;
  height: 124px;
  &-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 43.5px;
  }
}
.attention_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #6e7a88;
  margin-top: 20px;
}
