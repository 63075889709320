/**
 * Ant Design 的样式变量，此文件为 .scss 并非 .less 请使用 $xxx 定义变量。
 * 文档: https://ant.design/docs/react/customize-theme-cn#Ant-Design-%E7%9A%84%E6%A0%B7%E5%BC%8F%E5%8F%98%E9%87%8F
 * 所有样式变量在这里查看: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 */
/** 框架提供的变量 */
$primary-color: #007d96; // 全局主色

/** 以下都是本项目自定义颜色 */
$color-warning: #faad14; // 警示⾊

$color-assist: #ef7a64; // 辅助⾊

$color-selectd: #e5f2f4; // 导航选中⾊

$color-list: #f5fafb; // 列表⾊

/** 文本颜色 */
$color-txt: #0d2238;

$color-txt-60: #6e7a88;

$color-txt-40: #9ea7af;

$color-txt-30: #b6bcc3;

$color-txt-list: #1da7c2; // 列表文字按钮色

$color-txt-eary: #ef7a64;

/** 线色 */
$border-clr: #dbdee1; // 输入边框线色

$border-clr-d: #e9ebed; // 分割线

/** 背景色 */
$backround-bj: #f1f5f7;

/** ⽤于突出数据显示 */
$font-size-40: 40px;
/** ⽤于⼤标题显示 */
$font-size-32: 32px;
/** ⽤于⼩标题、弹窗显示 */
$font-size-20: 20px;
/** ⽤于正⽂内容 */
$font-size-14: 14px;
/** ⽤于提示性⽂字、单位显示 */
$font-size-12: 12px;

/** ⼩圆⻆ */
$border-r-4: 4px;
/** 中圆⻆ */
$border-r-8: 8px;
/** ⼤圆⻆ */
$border-r-20: 20px;
/** 全圆⻆ */
$border-r-50: 50px;

/** 分割线 */
$border-division: 1px solid $border-clr-d;

$input-border-color: $border-clr;
$border-radius-base: $border-r-8;
/**下拉列表*/
$select-item-selected-color: #007d96;
$select-item-selected-bg: rgba(0, 125, 150, 0.1);
$select-item-active-bg: rgba(0, 125, 150, 0.1);

$height-base: 36px;
// button
$btn-default-color: #007d96;
$btn-default-bg: rgba(0, 125, 150, 0.15);
$btn-default-border: 0;
// card
$card-radius: 20px;

/** 表格选中色 */
$table-selected-row-bg: #e5f2f4;
/** 表格 hover */
$table-row-hover-bg: #e5f2f4;
