.loading {
  position: fixed !important;
  height: 100%;
  width: 100%;
  z-index: 99999;

  :global(.ant-spin-container) {
    height: 100%;
  }

  &.hide {
    display: none;
  }
}
