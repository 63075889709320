@import './antd.css';
@import '@medical/components-pc/styles/global.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: PingFangSC-Regular, PingFang SC;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.right_top_icon {
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: url('../assets/imgs/right_top_icon.png') no-repeat;
    background-size: 100% 100%;
    // background-color: pink;
  }
}
//设置滚动条样式 （全局）
::-webkit-scrollbar {
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
  border-radius: 10px;
  background-color: #9ea7af;
}
::-webkit-scrollbar-corner {
  display: none;
}

//设置select 滚动条样式
.rc-virtual-list-scrollbar {
  .rc-virtual-list-scrollbar-thumb {
    width: 4px !important;
    background: #9ea7af !important;
  }
}
